// tabs
import LamanUtama from './tabs/LamanUtama';
import Perkhidmatan from './tabs/Perkhidmatan';
import TermaSyarat from './tabs/TermaSyarat';
import Galeri from './tabs/Galeri';
import HubungiKami from './tabs/HubungiKami';

function Content(){
	var rename = "";
	var list = ["Laman Utama", "Perkhidmatan", "Terma & Syarat", "Galeri", "Hubungi Kami"];
	return (
		<>
			<div className="col-12 text-center">
				<img alt="" src="images/logo.png" width="80%"/>
			</div>
			<div className="col-12 pt-3 text-center">
				<nav className="navbar navbar-expand-md navbar-dark">
					<font className="d-md-none navbar-brand">Menu</font>
					<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
						<span className="navbar-toggler-icon"></span>
					</button>
					<div className="collapse navbar-collapse" id="collapsibleNavbar">
						<ul className="nav nav-pills d-md-inline-flex" role="tablist">
							{
								list.map((item, index) => {
									rename = item.split(' ');
									rename = rename.map(item => 
										item.charAt(0).toUpperCase() + item.slice(1)
										
									);
									rename = rename.join('');
									rename = rename.replace(/[^\w\s]/gi, '');
									return <li key={index} className="nav-item">
										<a className={"nav-link"+(index === 0?' active':'')} data-toggle="pill" href={'#'+rename}>{item}</a>
									</li>
								})
							}
						</ul>
					</div>  
				</nav>
			</div>
			
			<div className="col-12">
				<div className="tab-content">
					<LamanUtama/>
					<Perkhidmatan/>
					<TermaSyarat/>
					<Galeri/>
					<HubungiKami/>
				</div>
			</div>
		</>
	);
}
export default Content;