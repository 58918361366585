const Galeri = () => {
	return (
		<div id="Galeri" className="container tab-pane fade"><br/>
			<div className="row">
				<div className="col-12">
					<div id="demo2" className="carousel slide" data-ride="carousel">
						<div className="carousel-inner">
							<div className="carousel-item active">
								<img alt="" src="images/gallery/1.jpg"/>
							</div>
							<div className="carousel-item">
								<img alt="" src="images/gallery/2.jpg"/>
							</div>
							<div className="carousel-item">
								<img alt="" src="images/gallery/3.jpg"/>
							</div>
							<div className="carousel-item">
								<img alt="" src="images/gallery/4.jpg"/>
							</div>
							<div className="carousel-item">
								<img alt="" src="images/gallery/5.jpg"/>
							</div>
							<div className="carousel-item">
								<img alt="" src="images/gallery/6.jpg"/>
							</div>
							<div className="carousel-item">
								<img alt="" src="images/gallery/7.jpg"/>
							</div>
							<div className="carousel-item">
								<img alt="" src="images/gallery/8.jpg"/>
							</div>
							<div className="carousel-item">
								<img alt="" src="images/gallery/9.jpg"/>
							</div>
							<div className="carousel-item">
								<img alt="" src="images/gallery/10.jpg"/>
							</div>
						</div>
						<a className="carousel-control-prev" href="#demo2" data-slide="prev">
							<span className="carousel-control-prev-icon"></span>
						</a>
						<a className="carousel-control-next" href="#demo2" data-slide="next">
							<span className="carousel-control-next-icon"></span>
						</a>
					</div>
				</div>
				<div className="col-12">
					<div className="col-12 white-back" style={{overflow:'scroll',height:'350px'}}>
						<div className="row">
							<div className="col-6 col-md-3 py-2 active" data-target="#demo2" data-slide-to="0">
								<img alt="" src="images/gallery/1.jpg" width="100%"/>
							</div>
							<div className="col-6 col-md-3 py-2" data-target="#demo2" data-slide-to="1">
								<img alt="" src="images/gallery/2.jpg" width="100%"/>
							</div>
							<div className="col-6 col-md-3 py-2" data-target="#demo2" data-slide-to="2">
								<img alt="" src="images/gallery/3.jpg" width="100%"/>
							</div>
							<div className="col-6 col-md-3 py-2" data-target="#demo2" data-slide-to="3">
								<img alt="" src="images/gallery/4.jpg" width="100%"/>
							</div>
							<div className="col-6 col-md-3 py-2" data-target="#demo2" data-slide-to="4">
								<img alt="" src="images/gallery/5.jpg" width="100%"/>
							</div>
							<div className="col-6 col-md-3 py-2" data-target="#demo2" data-slide-to="5">
								<img alt="" src="images/gallery/6.jpg" width="100%"/>
							</div>
							<div className="col-6 col-md-3 py-2" data-target="#demo2" data-slide-to="6">
								<img alt="" src="images/gallery/7.jpg" width="100%"/>
							</div>
							<div className="col-6 col-md-3 py-2" data-target="#demo2" data-slide-to="7">
								<img alt="" src="images/gallery/8.jpg" width="100%"/>
							</div>
							<div className="col-6 col-md-3 py-2" data-target="#demo2" data-slide-to="8">
								<img alt="" src="images/gallery/9.jpg" width="100%"/>
							</div>
							<div className="col-6 col-md-3 py-2" data-target="#demo2" data-slide-to="9">
								<img alt="" src="images/gallery/10.jpg" width="100%"/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Galeri;