const Perkhidmatan = () => {
	return (
		<div id="Perkhidmatan" className="container tab-pane fade"><br/>
			<div className="row">
				<div className="col-12">
					<div className="col-12 white-back py-3">
						<img alt="" width="100%" src="images/service.jpg"/>
					</div>
				</div>
				<div className="col-12">
					<div className="col-12">
						<div className="row">
							<div className="col-12 col-md-4 white-back">
									<div className="theme-box2 text-center text-md-right p-md-3 theme-font">
										Perkhidmatan
										<br className="d-none d-md-block"/>
										<br className="d-none d-md-block"/>
									</div>
							</div>
							<div className="col-12 col-md-8 normaltext white-back">
								<b>SuperMom Perkhidmatan Dobi</b> komited dalam melaksanakan perkhidmatan yang telah dirangka bagi memudahkan pelanggan-pelanggan meringankan beban kerja mendobi di kediaman masing-masing setelah seharian bekerja. Kami juga turut menawarkan perkhidmatan yang sama kepada badan-badan kerajaan dan swasta, organisasi, persatuan, sekolah-sekolah, hotel-hotel bajet, pengusaha homestay dan sebagainya.
								<br/><br/>
								Antara jenis perkhidmatan yang ditawarkan oleh syarikat termasuklah :-
								<br/><br/>
							</div>
							<div className="col-12 col-md-4 white-back box">
								<table width="100%">
									<tbody>
										<tr>
											<td width="50%" valign="top" className="box-image" style={{backgroundImage:'url(images/small1.jpg)'}}>
												Perkhidmatan melipat pakaian / Folding
											</td>
											<td className="pl-2">
												Pakaian yang dicuci oleh pelanggan dikeringkan menggunakan mesin pengering dan dilipat seterussnya dibungkuskan ke dalam plastik.
												<br/><br/>
												<br/>
											</td>
										</tr>
									</tbody>
								</table>
								<br/><br/>
							</div>
							
							<div className="col-12 col-md-4 white-back box">
								<table width="100%">
									<tbody>
										<tr>
											<td width="50%" valign="top" className="box-image" style={{backgroundImage:'url(images/small2.jpg)'}}>
												Perkhidmatan cucian kering / Dry Cleaning
											</td>
											<td className="pl-2">
												Proses ini memerlukan bahan kimia dan alatan khusus. Hanya pakaian tertentu sahaja yang boleh. Pakaian tersebut digosok dan digantung di penyangkut baju serta dibungkuskan.
												<br/><br/>
											</td>
										</tr>
									</tbody>
								</table>
								<br/><br/>
							</div>
							
							<div className="col-12 col-md-4 white-back box">
								<table width="100%">
									<tbody>
										<tr>
											<td width="50%" valign="top" className="box-image" style={{backgroundImage:'url(images/small3.jpg)'}}>
												Perkhidmatan cucian karpet /  Carpet Cleaning
											</td>
											<td className="pl-2">
												Karpet akan dicuci menggunakan  kaedah standard  mengikut jenis karpet. Karpet akan dibungkus rapi bagi mengelakkan wangian karpet pudar apabila disimpan lama oleh pelanggan.
											</td>
										</tr>
									</tbody>
								</table>
								<br/><br/>
							</div>
							
							<div className="col-12 col-md-4 white-back box">
								<table width="100%">
									<tbody>
										<tr>
											<td width="50%" valign="top" className="box-image" style={{backgroundImage:'url(images/small4.jpg)'}}>
												Perkhidmatan cucian biasa / Normal Wash
											</td>
											<td className="pl-2">
												Proses pencucian menggunakan mesin basuh biasa dan dikeringkan menggunakan mesin pengering. Pakaian tersebut akan dilipat dan dibungkuskan ke dalam plastik.
											</td>
										</tr>
									</tbody>
								</table>
								<br/><br/>
							</div>
							
							<div className="col-12 col-md-4 white-back box">
								<table width="100%">
									<tbody>
										<tr>
											<td width="50%" valign="top" className="box-image" style={{backgroundImage:'url(images/small5.jpg)'}}>
												Perkhimatan menggosok / Ironing
											</td>
											<td className="pl-2">
												Pakaian digosok menggunakan teknik-teknik terlatih dan akan dilipat atau digantung di penyangkut baju mengikut permintaan pelanggan.
												<br/><br/>
												<br/>
											</td>
										</tr>
									</tbody>
								</table>
								<br/><br/>
							</div>
							
							<div className="col-12 col-md-4 white-back box">
								<table width="100%">
									<tbody>
										<tr>
											<td width="50%" valign="top" className="box-image" style={{backgroundImage:'url(images/small6.jpg)'}}>
												Perkhidmatan penjagaan fabrik / Fabric Care
											</td>
											<td className="pl-2">
												Fabrik akan dicuci menggunakan bahan-bahan kimia tertentu mengikut jenis kotoran pada fabrik. Selepas dicuci, fabrik akan dilipat dan digosok mengikut permintaan pelanggan. 
											</td>
										</tr>
									</tbody>
								</table>
								<br/><br/>
							</div>
							
							<div className="col-12 col-md-4 white-back box">
								<table width="100%">
									<tbody>
										<tr>
											<td width="50%" valign="top" className="box-image" style={{backgroundImage:'url(images/small7.jpg)'}}>
												Perkhidmatan cucian tangan / Hand Wash
											</td>
											<td className="pl-2">
												Proses ini menggunakan cucian tangan dan dikeringkan secara manual. Pakaian tersebut dilipat dan dibungkuskan ke dalam plastik.
												<br/><br/><br/>
											</td>
										</tr>
									</tbody>
								</table>
								<br/><br/>
							</div>
							
							<div className="col-12 col-md-4 white-back box">
								<table width="100%">
									<tbody>
										<tr>
											<td width="50%" valign="top" className="box-image" style={{backgroundImage:'url(images/small8.jpg)'}}>
												Penggunaan detergent di premis / Quality Detergent Usage in the Premise
											</td>
											<td className="pl-2">
												Bahan cucian ini memberi impak yang besar terhadap kualiti cucian yang memberikan wangian berpanjangan.
												<br/><br/>
												<br/><br/>
											</td>
										</tr>
									</tbody>
								</table>
								<br/><br/>
							</div>
							
							<div className="col-12 col-md-4 white-back box">
								<table width="100%">
									<tbody>
										<tr>
											<td width="50%" valign="top" className="box-image" style={{backgroundImage:'url(images/small9.jpg)'}}>
												Perkhidmatan Penghantaran & Pengambilan / Pickup & Delivery
											</td>
											<td className="pl-2">
												Perkhidmatan ini disediakan bagi pelanggan yang kesuntukan masa untuk mendobi. 
												<br/><br/>
												<br/><br/>
												<br/>
											</td>
										</tr>
									</tbody>
								</table>
								<br/><br/>
							</div>
							
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Perkhidmatan;