const TermaSyarat = () => {
	return (
		<div id="TermaSyarat" className="container tab-pane fade"><br/>
			<div className="row">
				<div className="col-12">
					<div className="col-12 white-back py-3">
						<div className="row">
							<div className="col-12 col-md-7 theme-box2 theme-font text-right pb-2">
								Terma & Syarat
							</div>
						</div>
					</div>
					<div className="col-12 white-back py-3 terma">
						1) Harga Perkhidmatan yang ditawarkan di premis adalah harga tetap.
						<br/><br/>2) Semua artikel termasuk sut mandi pantai, kelambu, tirai/langsir, set kerusi, alas meja, permaidani, kain dan sebarang artikel yang diperbuat dari bahan sintetik yang diterima untuk cucian dan/atau cucian kering adalah <b>sepenuhnya risiko pelanggan sendiri dan kami tidak bertanggungjawab</b> atas sebarang kerosakan seperti penyusutan, perubahan warna, pudar warna, koyak dan apa-apa  kerosakan lain yang mungkin timbul semasa pencucian dan/atau pembersihan yang sama.
						<br/><br/>3) Setiap resit pembelian hanya sah selama <b>1 BULAN</b>.
						<br/><br/>4) Sila semak barangan anda sebelum meninggalkan premis. Segala aduan selepas meninggalkan premis adalah tidak dilayan.
						<br/><br/>5) Sebarang tuntutan atas <b>KEROSAKAN ATAU KEHILANGAN</b> terhad kepada <b>CUCIAN KERING</b> sahaja, gantirugi hanya pada had maksimum 5 kali ganda dari harga yang ternyata pada resit pelangganan perkhidmatan.Ganti Rugi adalah dalam bentuk duit atau perkhidmatan cucian yang lain.
						<br/><br/>6) Pelanggan diminta untuk mengambil dan membayar barangan dalam jangkamasa <b>30 HARI (SEBULAN)</b> selepas tarikh menghantar.
						<br/><br/>7) Kos penyimpanan barangan pelanggan akan dikenakan sebanyak <b>RM 5 SEBULAN  SELEPAS 2 (DUA) BULAN TIDAK DITUNTUT</b> bagi memastikan barangan pelanggan dalam keadaan yg  sempurna.
						<br/><br/>8) Sila periksa poket pakaian sebelum menyerahkan pakaian kepada kakitangan dobi. Pihak pengurusan tidak akan bertanggungjawab atas kehilangan barangan dari poket pakaian.
						<br/><br/>7) Pihak pengurusan berhak menolak perkhidmatan yang tidak mengikut Terma & Syarat Syarikat.
						<br/><br/>8) Barangan Pelanggan akan <b>dilupuskan</b> jika tidak dituntut selepas <b>TIGA (3) BULAN</b>.
					</div>
				</div>
			</div>
		</div>
	);
};

export default TermaSyarat;