import React from 'react';
import ReactDOM from 'react-dom/client';
//import { BrowserRouter, Routes, Route } from "react-router-dom";

// library
import 'bootstrap-4-react';
//import GoogleMapReact from 'google-map-react';

// normal source
import './custom.css'; 
import './bootstrap-rewrite.css'; 

// component
import Content from './Content';
import Footer from './Footer';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div className="container">
	<div className="row">
		<Content></Content>
		<Footer text="&copy;SEJAK 2018, SUPERMOM ENTERPRISE"></Footer>
	</div>
</div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

