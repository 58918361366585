import SimpleMap from '../SimpleMap';

const Tab = () => {
	return (
		<div id="HubungiKami" className="container tab-pane fade"><br/>
			<div className="row">
				<div className="col-12">
					<SimpleMap></SimpleMap>
				</div>
				
				<div className="col-12">
					<div className="col-12 white-back py-3 py-md-5">
						<div className="row">
							<div className="col-12 col-md-8 text-white text-center text-md-right">
								<div className="theme-box2">
									<div className="pr-2 py-md-2 text-center text-md-left d-inline-block theme-font">
										HUBUNGI KAMI
									</div>
								</div>
							</div>
							<div className="col-0 col-md-4"></div>
							<div className="col-12 col-md-4 py-3">
								<b>SUPERMOM LAUNDRETTE</b>
								<br/>No 11 GF, Lrg Desa Manggis 1/1,
								<br/>Tmn Desa Manggis,
								<br/>Jln Tunku Kurshiah,
								<br/>70400 Seremban.
								<br/>N.Sembilan.
							</div>
							<div className="col-12 col-md-4 normaltext py-3">
								Hubungi Kami Di
								<br/><b>+6012-527 1701 / +6019-316 7322</b>
								​<br/>
								<br/>Email Kami Di
								<br/><b>supermomlaundrette@gmail.com</b>
							</div>
							<div className="col-12 col-md-4 normaltext py-3">
								<b style={{fontSize:'20px'}}>Waktu Urusniaga</b>
								<br/><br/>
								<table border="1" cellPadding="5">
									<tbody>
										<tr>
											<th>Isnin - Jumaat</th><td>9:00am - 6:30pm</td>
										</tr>
										<tr>
											<th>Sabtu</th><td>9:00am - 5:00pm</td>
										</tr>
										<tr>
											<th className="pr-3">Ahad & Cuti Umum</th><th>TUTUP</th>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Tab;