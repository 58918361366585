import React from "react";
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => (
<div style={{
	background:' rgba(196, 115, 0, 0.7)',
	width:'200px',
	marginLeft:'-100px',
	marginTop:'-55px',
	color:'white',
	textAlign:'center',
	borderRadius:'15px',
	animation: 'bounce 0.5s',
	animationDirection: 'alternate',
	animationTimingFunction: 'cubic-bezier(.5, 0.05, 1, .5)',
	animationIterationCount: 'infinite',
	padding:'5px'}}
>
	<img alt="" src="images/logo.png" style={{
		width:"calc(100% - 10px)"
	}}/>
	<div className="bottom-arrow"></div>
</div>
);

export default function SimpleMap(){
	const defaultProps = {
		center: {
			lat: 2.733811304711732, 
			lng: 101.95321646650564
		},
		zoom: 17
	};
	/*const handleApiLoaded = (map, maps) => {
		// use map and maps objects
	};*/

	return (
		// Important! Always set the container height explicitly
		<div style={{ height: '500px', width: '100%' }}>
			<GoogleMapReact
			bootstrapURLKeys={{ key: "AIzaSyDn_R1FufAU3jfAtYqnB2itzguBFhM_tLM" }}
			defaultCenter={defaultProps.center}
			defaultZoom={defaultProps.zoom}
			//yesIWantToUseGoogleMapApiInternals
			//onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
			>
				<AnyReactComponent
				lat={defaultProps.center.lat}
				lng={defaultProps.center.lng}
				text="SuperMom Perkhidmatan Dobi (Laundry Services)"
				/>
			</GoogleMapReact>
		</div>
	);
}