function Footer(a) {
  return (
	<div className="col-12">
		<div className="col-12">
			<div style={{color:'rgba(255,255,255,0.9)'}}>
				<div className="col-12 bg-dark text-center py-1 py-md-5">
					{a.text}
				</div>
			</div>
		</div>
	</div>
  );
}
export default Footer;