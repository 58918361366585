const LamanUtama = () => {
	return (
		<div id="LamanUtama" className="container tab-pane active"><br/>
			<div className="row">
				<div className="col-12">
					<div id="demo" className="carousel slide" data-ride="carousel">

						<ul className="carousel-indicators">
							<li data-target="#demo" data-slide-to="0" className="active"></li>
							<li data-target="#demo" data-slide-to="1"></li>
							<li data-target="#demo" data-slide-to="2"></li>
							<li data-target="#demo" data-slide-to="3"></li>
							<li data-target="#demo" data-slide-to="4"></li>
							<li data-target="#demo" data-slide-to="5"></li>
							<li data-target="#demo" data-slide-to="6"></li>
						</ul>

						<div className="carousel-inner">
							<div className="carousel-item active">
								<img alt="" src="images/slide/1.jpg"/>
							</div>
							<div className="carousel-item">
								<img alt="" src="images/slide/2.jpg"/>
							</div>
							<div className="carousel-item">
								<img alt="" src="images/slide/3.jpg"/>
							</div>
							<div className="carousel-item">
								<img alt="" src="images/slide/4.jpg"/>
							</div>
							<div className="carousel-item">
								<img alt="" src="images/slide/5.jpg"/>
							</div>
							<div className="carousel-item">
								<img alt="" src="images/slide/6.jpg"/>
							</div>
							<div className="carousel-item">
								<img alt="" src="images/slide/7.jpg"/>
							</div>
						</div>

						<a className="carousel-control-prev" href="#demo" data-slide="prev">
							<span className="carousel-control-prev-icon"></span>
						</a>
						<a className="carousel-control-next" href="#demo" data-slide="next">
							<span className="carousel-control-next-icon"></span>
						</a>
					</div>
				</div>
				
				<div className="col-12 p-3">
					<img alt="" src="images/ada-dry-cleaning.gif" width="100%"/>
				</div>
				<div className="col-12 pb-3">
					<a href="tel:+6019-6610571" className="theme-link">
						<div className="theme-box text-center p-3" style={{width:'100%',fontSize:'35px',fontWeight:'bold'}}>
							HUBUNGI SEKARANG
						</div>
					</a>
				</div>
				
				<div className="col-12 col-md-4 text-white text-center text-md-right">
					<div className="theme-box2">
						<div className="pr-2 py-md-2 text-center text-md-left d-inline-block theme-font">
							KENAPA 
							<br className="d-none d-md-block"/>
							MEMILIH 
							<br className="d-none d-md-block"/>
							KAMI?
						</div>
					</div>
				</div>
				
				<div className="col-12 col-md-8">
					<img alt="" src="images/lebih-relaks.gif" width="100%"/>
				</div>
				
				<div className="col-12">
					<div className="white-back">
						<div className="row">
							<div className="col-12 col-md-4 subtext p-3">
								<center>
									<img alt="" src="images/fabric care.png"/>
									<br/>
									<font className="subtitle"><b>SUPER</b>CARE</font>
								</center>
								<div className="px-5 pt-3">
									<b>Perkhidmatan Penjagaan Fabrik</b>
									<br/>
									Fabrik akan dicuci menggunakan bahan-bahan kimia tertentu mengikut jenis kotoran pada fabrik. Selepas dicuci, fabrik akan dilipat dan digosok mengikut permintaan pelanggan.
								</div>
							</div>
							<div className="col-12 col-md-4 subtext p-3">
								<center>
									<img alt="" src="images/dry cleaning.png"/>
									<br/>
									<font className="subtitle"><b>SUPER</b>DRY-C</font>
								</center>
								<div className="px-5 pt-3">
									<b>Perkhidmatan Cucian Kering</b>
									<br/>
									Proses pencucian pakaian ini menggunakan bahan kimia dan teknik tertentu tanpa menggunakan air. Proses ini memerlukan bahan kimia dan alatan khusus. Hanya pakaian tertentu sahaja yang boleh atau memerlukan dry cleaning. Kemudian pakaian tersebut akan digosok dan digantung di penyangkut baju serta dibungkus menggunakan plastik lutsinar.
								</div>
							</div>
							<div className="col-12 col-md-4 subtext p-3">
								<center>
									<img alt="" src="images/carpet.png" style={{paddingTop:'14px'}}/>
									<br/>
									<font className="subtitle"><b>SUPER</b>CARPET</font>
								</center>
								<div className="px-5 pt-3">
									<b>Perkhidmatan Cucian Karpet</b>
									<br/>
									Karpet akan dicuci menggunakan cucian tangan, mesin biasa, dry cleaning atau kaedah standard pencucian karpet mngikut jenis karpet yang dihantar. Kemudian karpet akan dibungkus rapi menggunakan plastik lutsinar bagi mengelakkan wangian karpet pudar apabila disimpan lama oleh pelanggan.
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LamanUtama;